import { NgModule } from '@angular/core';
import { ExtraOptions, Route, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { AuthRedirectGuard } from './auth/auth-redirect.guard';

export const appRoutes: Route[] = [
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthRedirectGuard]
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/modules-wrapper.module').then(
        (m) => m.ModulesWrapperModule
      ),
    canActivate: [AuthGuard]
  },
  { path: '', redirectTo: '/trip-status', pathMatch: 'full' },
  { path: '**', redirectTo: 'trip-status' }
];

const config: ExtraOptions = {
  useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
